.basket {
  transition: opacity 0.5s;
  opacity: 1;
}
.basket.hide {
  opacity: 0;
  pointer-events: none;
}

.egg-content {
  color: white;
  font-family: Rubik, sans-serif;
}

.egg-action:hover {
  filter: brightness(70%);
}
