.alchemy-app {
  color: white;
  font-family: Rubik, sans-serif;
}

.alchemy-app > * .container {
  max-width: 900px;
}

.alchemy-faucet-nav {
  background: linear-gradient(269.26deg, #ac35a0 6.59%, #2f42aa 94.04%);
  height: 40px;
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 14px;
}

.alchemy-faucet-link-nav-col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.alchemy-faucet-link-nav {
  border: 0px;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  color: white;
  transition: 0.5s;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  flex: 1 1 auto;
}

.alchemy-faucet-link-nav-current-network {
  background: white;
  color: black;
}

.alchemy-faucet-link-nav:hover {
  background: white;
  color: black;
}

.alchemy-faucet-health-nav-col,
.alchemy-faucet-login-nav-col {
  flex: 0 0 auto;
  width: 25%;
}

.alchemy-faucet-beta {
  color: #caced4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 0px;
}

.alchemy-faucet-title-component {
  padding-top: 55px;
}

.alchemy-faucet-title {
  text-align: center;
  line-height: 56px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.alchemy-faucet-subtitle {
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 10px;
}

.alchemy-faucet-request-component {
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #ffffff;
  box-sizing: border-box;
}

.alchemy-faucet-request-component-embeded {
  margin-left: 36px;
  margin-right: 36px;
}

.alchemy-faucet-request-component-shadow {
  width: 100%;
  height: 100%;
  background: #46cd92;
  opacity: 0.7;
  filter: blur(100px);
  border-radius: 8;
  z-index: -1;
}

.grecaptcha-badge {
  z-index: 1000;
}

.alchemy-brand-tagline {
  font-size: 12px;
}

.alchemy-faucet-panel-input-text {
  background: #f9f9fb;
  border: 1px solid #caced4;
  box-sizing: border-box;
  border-radius: 8px;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
}

.alchemy-faucet-panel-input-text::placeholder {
  color: #a0a4ac;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.alchemy-faucet-button {
  background: #3f51b8;
  border: 0px;
  border-radius: 8px;
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.alchemy-faucet-button:focus,
.alchemy-faucet-button:hover {
  outline: none;
  outline-offset: none;
  background: #455acf;
}

.alchemy-faucet-link-footer-container {
  display: none;
}

.alchemy-dialog {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: #52555b;
  height: 100%;
  padding: 55px 55px;
  margin-top: 35px;
}

.alchemy-dialog p {
  margin-bottom: 35px;
}

/* Small devices (smaller phones that are vertical) */
@media only screen and (max-width: 767px) {
  .alchemy-faucet-link-nav-col {
    display: none;
  }

  .alchemy-faucet-health-nav-col,
  .alchemy-faucet-login-nav-col {
    width: 50%;
  }

  .alchemy-faucet-link-footer-container {
    display: block;
  }

  .alchemy-faucet-link-footer-group {
    flex-direction: column;
    display: flex;
    position: relative;
    vertical-align: middle;
    flex-direction: column;
  }

  .alchemy-faucet-link-footer {
    background: #3f51b8;
    border: 0px;
    border-radius: 8px;
    width: 100%;
    font-weight: bold;
    padding: 8px;
    margin: 2px;
    text-align: center;
    text-decoration: none;
    color: white;
    transition: 0.5s;
    text-transform: capitalize;
  }

  .alchemy-faucet-link-footer-current-network {
    background: #c24fb6;
    text-decoration: none;
    color: white;
  }

  .alchemy-faucet-link-footer:hover {
    background: #c24fb6;
    text-decoration: none;
    color: white;
  }

  .alchemy-app-content-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .alchemy-faucet-title-component {
    padding-top: 15px;
  }

  .alchemy-faucet-request-component-embeded {
    margin-left: 25px;
    margin-right: 25px;
  }

  .alchemy-dialog {
    padding: 30px 25px;
  }
}

.alchemy-dialog-title {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #1f2431;
}

.alchemy-faucet-table table {
  width: 100%;
  border-radius: 4px;
}

.alchemy-faucet-table-header {
  background: linear-gradient(269.26deg, #c24fb6 6.59%, #3f51b8 94.04%);
  border-radius: 8px 8px 0px 0px;
}

.alchemy-faucet-table-header-data {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 14px 24px;
}

.alchemy-faucet-table-body > .row {
  background: #f9f9fb;
  color: #52555b;
  border: 1px solid #caced4;
  border-top-width: 0px;
}

.alchemy-faucet-table-data {
  font-size: 16px;
  line-height: 19px;
  font-weight: 100;
  padding-top: 15px;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  margin: auto;
}

.alchemy-faucet-table-data:nth-child(1),
.alchemy-faucet-table-header-data:nth-child(1) {
  text-align: start;
  justify-content: flex-start;
  padding: 14px 7px 14px 14px;
}

.alchemy-faucet-table-data:nth-child(2),
.alchemy-faucet-table-header-data:nth-child(2) {
  text-align: start;
  justify-content: flex-start;
  padding: 14px 0px;
}

.alchemy-faucet-table-body .row:last-child {
  border-radius: 0px 0px 8px 8px;
}

.alchemy-faucet-table-data > p {
  text-overflow: ellipsis;
  min-width: 8ch;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.alchemy-faucet-table-data > * a:link {
  color: #52555b;
}

.alchemy-faucet-table-data > * a:visited {
  color: #3e4044;
}

.alchemy-faucet-lds-ring {
  display: block;
  position: relative;
  animation: fadein 0.3s;
}

.alchemy-faucet-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #52555b;
  border-radius: 50%;
  border-color: #52555b transparent transparent transparent;
  animation: alchemy-faucet-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.alchemy-faucet-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.alchemy-faucet-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.alchemy-faucet-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes alchemy-faucet-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alchemy-faucet-code-text {
  font-family: "Courier New", Courier, monospace;
  font-size: inherit;
  font-weight: bold;
  background: #ffffff;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.3rem;
}

.faucet-banner-container {
  max-height: 0px;
  opacity: 0;
  transition:
    opacity 300ms,
    max-height 300ms;
  margin: 22px 0px;
}
.faucet-banner-container.show {
  max-height: 500px;
  opacity: 1;
}

.faucet-banner {
  background: #cd4f38; /*rgba(69, 141, 79, 0.75); */
  width: 100%;
  border-radius: 4px;
  border-color: #cd4f38; /*rgba(69, 141, 79, 1);*/
  text-align: center;
  color: white;
  margin-bottom: 0px;
}

/* unvisited link */
.faucet-banner > * a:link {
  color: white;
}
/* visited link */
.faucet-banner > * a:visited {
  color: #dadada;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}

.faucet-request-item-enter {
  opacity: 0;
}
.faucet-request-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.faucet-request-item-exit {
  opacity: 1;
}
.faucet-request-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.alchemy-faucet-login-description {
  color: black;
}

.alchemy-faucet-upsell-modal-body {
  color: black;
  font-family: "Ppneuemontreal book", Rubik, Courier, monospace;
  font-size: 1.1rem;
  margin-left: 10px;
  text-align: center;
}

.stop-scrolling {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.optimism-superchain-info-header {
  text-align: center;
}

.optimism-superchain-info-steps-container {
  display: flex;
  flex-direction: column;
}

.optimism-superchain-faucet-button {
  width: 250px;
}

.support-chain-block-footer {
  margin: auto;
}

.optimism-superchain-error {
  background: #cd4f38; /*rgba(69, 141, 79, 0.75); */
  width: 100%;
  border-radius: 4px;
  border-color: #cd4f38; /*rgba(69, 141, 79, 1);*/
  text-align: center;
  color: white;
  line-height: 28px;
  height: 28px;
  padding: 0 14px;
}

.faucet-announcement-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #68fcba;
  height: 40px;
}

.faucet-announcement-text {
  font-weight: 500;
  color: black;
}
